import * as React from "react"
import Layout from "../Layout/Layout"

export default function NotFound() {
  return (
    <>
      <Layout title="Page Not Found">
        <div id="error404">
          <div id="content">
            <div className="container">
              <h1 className="text-center">
                404
                <br />
                Page Not Found
              </h1>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
